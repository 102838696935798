import { createSlice } from "@reduxjs/toolkit";
import {
  getAllQuotations,
  getQuotations,
  updateQuotations,
} from "./qutationsApi.js";

// Slice for managing master category state
const qutationsSlice = createSlice({
  name: "quotations",
  initialState: {
    allQuotations: [],
    quotations: [],
    loading: false,
    error: null,
    dataLimit: 0,
    currentPage: 0,
    totalPages: 0,
    totalQuotations: 0,
  },
  extraReducers: (builder) => {
    // GET MAIN_CATEGORY FILTER WISE DATA
    builder.addCase(getQuotations.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getQuotations.fulfilled, (state, action) => {
      state.loading = false;
      state.quotations = action.payload.data;
      state.dataLimit = action.payload.meta.limit;
      state.currentPage = action.payload.meta.page;
      state.totalPages = action.payload.meta.totalPages;
      state.totalQuotations = action.payload.meta.total;
      state.dataFetched = true;
    });
    builder.addCase(getQuotations.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    builder.addCase(getAllQuotations.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getAllQuotations.fulfilled, (state, action) => {
      state.loading = false;
      state.allQuotations = action.payload.data;
    });
    builder.addCase(getAllQuotations.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    builder.addCase(updateQuotations.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(updateQuotations.fulfilled, (state, action) => {
      state.loading = false;
      const updatedQutations = action.payload.quotation._id;
      state.quotations = state.quotations.filter(
        (qutations) => !updatedQutations.includes(qutations._id)
      );
    });
    builder.addCase(updateQuotations.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export default qutationsSlice.reducer;
