const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;

const API_URL_LOCAL = process.env.REACT_APP_BASE_URL_LOCAL;
const API_URL_PROD = process.env.REACT_APP_BASE_URL_PROD;

const SOCKET_URL_LOCAL = process.env.REACT_APP_ONLINE_USER_URL_LOCAL;
const SOCKET_URL_DEV = process.env.REACT_APP_ONLINE_USER_URL_DEV;

const API_URL_DEV = process.env.REACT_APP_BASE_URL_DEV;

const SOCKET_URL_PROD = process.env.REACT_APP_ONLINE_USER_URL_PROD_SOCKET;

const config = {
  base: {
    url: API_URL_LOCAL,
  },
  ws: {
    url: SOCKET_URL_LOCAL,
  },
};

console.log(config.base.url, "==config.base.url");
//CHECK FOR ENVIRONMENT
if (ENVIRONMENT === "prod") {
  config.base.url = API_URL_PROD;
  config.ws.url = SOCKET_URL_PROD;
} else if (ENVIRONMENT === "dev") {
  config.base.url = API_URL_DEV;
  config.ws.url = SOCKET_URL_DEV;
} else {
  config.base.url = API_URL_LOCAL;
  config.ws.url = SOCKET_URL_LOCAL;
}

export { config, ENVIRONMENT };
