import { createContext, useContext, useEffect, useState } from "react";
import { io } from "socket.io-client";
import { toast } from "react-toastify";
import { config } from "../../config/config";

const SocketContext = createContext();

export const SocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);
  const [isConnected, setIsConnected] = useState(false);
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  const socketUrl = config.ws.url;

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));

    if (!user || !user.id || !user.authName) {
      console.warn("⚠️ User not found in localStorage");
      return;
    }

    const newSocket = io(socketUrl, {
      transports: ["websocket"],
      reconnection: true,
      reconnectionAttempts: 10,
      reconnectionDelay: 5000,
      query: { userId: user.id, userName: user.authName },
    });

    newSocket.on("connect", () => {
      setIsConnected(true);
      toast.dismiss();
    });

    newSocket.on("disconnect", () => {
      setIsConnected(false);
      toast.warn("⚠️ Disconnected from chat, trying to reconnect...");
    });

    newSocket.on("connect_error", (err) => {
      console.error("🚨 Socket connection error:", err.message);
    });

    setSocket(newSocket);

    return () => newSocket.disconnect();
  }, []);

  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true);
      toast.dismiss();
      toast.success("✅ Internet restored!");
    };

    const handleOffline = () => {
      setIsOnline(false);
      toast.error("⚠️ No internet connection!");
    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  return (
    <SocketContext.Provider value={{ socket, isConnected, isOnline }}>
      {children}
    </SocketContext.Provider>
  );
};

export const useSocket = () => useContext(SocketContext);
